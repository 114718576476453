import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/opt/build/repo/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/opt/build/repo/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-playfair\"}],\"variableName\":\"playfair\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Lora\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-lora\"}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Cormorant_Garamond\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-cormorant\",\"weight\":\"300\"}],\"variableName\":\"cormorant\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/Analytics.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutProvider"] */ "/opt/build/repo/src/components/LayoutProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/navbar.tsx");
