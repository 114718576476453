"use client";

import * as React from "react";
import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";

import { Button } from "@/components/ui/button";

export function ThemeToggler({ id, label }: { id?: string; label?: string }) {
  const { setTheme, theme } = useTheme();

  return (
    <Button
      id={id}
      variant="secondary"
      size={label ? "lg" : "icon"}
      className="rounded-none p-3"
      aria-label="Alternar tema"
      onClick={() => setTheme(theme === "light" ? "dark" : "light")}
    >
      {label ? <p>{label}</p> : null}
      <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0 text-foreground" />
      <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100 text-primary" />
      <span className="sr-only">Alternar tema</span>
    </Button>
  );
}
