'use client';

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from "@/components/ui/sheet";
import { Separator } from "@/components/ui/separator";
import { ThemeToggler } from "@/components/theme-toggler";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LogIn, LogOut, MenuIcon, Search, Settings, User } from "lucide-react";
import Link from "next/link";
import { useRouter, usePathname } from "next/navigation";
import { signOut, useSession } from "next-auth/react";
import { useState, useEffect } from "react";

interface MenuProps {
  setIsSearchExpanded: (value: boolean) => void;
}

export const Menu: React.FC<MenuProps> = ({ setIsSearchExpanded }) => {
  const router = useRouter();
  const pathname = usePathname();
  const { data } = useSession();

  const [ref, setRef] = useState<string>();
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  // Close menu when route changes
  useEffect(() => {
    setMenuOpened(false);
  }, [pathname]);

  function navigateToProperty() {
    if (!ref || ref === "") return;
    router.push(`/imovel/MB${ref?.replace(/mb/gi, "")}`);
    setMenuOpened(false);
    setIsSearchExpanded(false);
  }

  return (
    <Sheet
      open={menuOpened}
      onOpenChange={setMenuOpened}
      aria-label="Menu de navegação"
    >
      <SheetTrigger className="ml-2">
        <MenuIcon
          className="h-8 w-8 text-primary"
          aria-label="Abrir menu de navegação"
        />
      </SheetTrigger>
      <SheetContent
        side="right"
        className="w-full text-primary"
        aria-labelledby="navigation-menu"
      >
        <SheetHeader>
          <ThemeToggler />
        </SheetHeader>

        <Separator className="mt-4" />

        <span className="flex relative w-full my-4">
          <Input
            className="text-base text-foreground h-12"
            inputMode="numeric"
            enterKeyHint="enter"
            placeholder="Pesquise pelo código MB"
            aria-label="Pesquisar imóvel pelo código"
            value={ref}
            onChange={(e) => setRef(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigateToProperty();
              }
            }}
          />
          <Button
            className="absolute right-0 top-0 h-full"
            onClick={navigateToProperty}
            aria-label="Pesquisar propriedade"
            title="Pesquisar propriedade"
          >
            <Search className="h-4 w-4 text-white" />
          </Button>
        </span>

        <Separator />

        <div className="grid gap-4 py-1">
          <nav className="grid gap-4 py-1" aria-label="Links de navegação">
            <ul className="flex flex-col space-y-6 font-semibold text-foreground py-8 text-xl">
              <li>
                <Link
                  href="/"
                  onClick={() => setMenuOpened(false)}
                  className="hover:text-shadow-md hover:shadow-primary transition-shadow"
                  aria-label="Início"
                >
                  Início
                </Link>
              </li>
              <li>
                <Link
                  href="/anuncie"
                  onClick={() => setMenuOpened(false)}
                  className="hover:text-shadow-md hover:shadow-primary transition-shadow"
                  aria-label="Anuncie seu imóvel"
                >
                  Anuncie seu Imóvel
                </Link>
              </li>
              <li>
                <Link
                  href="/gestao"
                  onClick={() => setMenuOpened(false)}
                  className="hover:text-shadow-lg hover:shadow-primary transition-shado"
                  aria-label="Gestão de imóveis"
                >
                  Gestão de Imóveis
                </Link>
              </li>
              <li>
                <Link
                  href="/sobre"
                  onClick={() => setMenuOpened(false)}
                  className="hover:text-shadow-md hover:shadow-primary transition-shadow"
                  aria-label="Sobre"
                >
                  Sobre a MBRAS
                </Link>
              </li>
              <li>
                <Link
                  href="/club"
                  onClick={() => setMenuOpened(false)}
                  className="hover:text-shadow-md hover:shadow-primary transition-shadow"
                  aria-label="MBRAS Club"
                >
                  MBRAS Club
                </Link>
              </li>
              <li>
                <Link
                  href="/brokers"
                  onClick={() => setMenuOpened(false)}
                  className="hover:text-shadow-md hover:shadow-primary transition-shadow"
                  aria-label="MBRAS Brokers"
                >
                  MBRAS Brokers
                </Link>
              </li>
              <li>
                <Link
                  href="/partners"
                  onClick={() => setMenuOpened(false)}
                  className="hover:text-shadow-md hover:shadow-primary transition-shadow"
                  aria-label="MBRAS Partners"
                >
                  MBRAS Partners
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="absolute bottom-0 left-0 w-full">
          <Separator className="my-4" />
          <div className="pt-0 p-4">
            {data ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    type="button"
                    className="w-full h-14 flex items-center justify-start space-x-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    aria-label={`Profile of ${data.user?.name || "User"}`}
                  >
                    <User className="mr-2 h-4 w-4" aria-hidden="true" />
                    <span>{data.user?.name || "Guest"}</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuLabel>Minha Conta</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <Link href="/profile">
                    <DropdownMenuItem>
                      <User className="mr-2 h-4 w-4" />
                      Perfil
                    </DropdownMenuItem>
                  </Link>
                  <Link href="/settings">
                    <DropdownMenuItem>
                      <Settings className="mr-2 h-4 w-4" />
                      Configurações
                    </DropdownMenuItem>
                  </Link>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={() => signOut()}>
                    <LogOut className="mr-2 h-4 w-4" />
                    Sair
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <div className="flex justify-center items-center">
                <Button
                  type="button"
                  className="w-full h-14 font-bold flex items-center justify-center space-x-2 focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
                  onClick={() => {
                    router.push("/auth");
                    setMenuOpened(false);
                  }}
                  aria-label="Entrar na conta"
                >
                  <LogIn className="h-8 w-8" aria-hidden="true" />
                  <span>Entrar</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
